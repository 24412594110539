.App {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;

  font-family: "Archivo", sans-serif;

}

#gossip-share-hidden{
  font-family: "Archivo", sans-serif;

}


.display {
  display: none;
}

.Notdisplay {
  display: block;
}

@media only screen and (min-width: 768px) {
  .display {
    display: block;
  }

  .Notdisplay {
    display: none;
  }

}