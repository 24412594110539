.theme-transition {
    position: fixed;
    width: 100px;
    height: 100px;
    background-color: var(--transition-color);
    border-radius: 50%;
    transform: scale(0);
    -webkit-backdrop-filter: grayscale(100%);
    backdrop-filter: brightness(20%);;
    animation: expandCircle 1.5s ease-out forwards;
    pointer-events: none;
    z-index: 9999;
  }
  
  @keyframes expandCircle {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    75% {
      transform: scale(50);
    }
    100% {
      transform: scale(50);
      opacity: 0;
    }
  }
  